"use client";

import { useShopConfig } from "~/hooks/useShopConfig";
import Image from "next/image";
import { useMemo } from "react";
import { useTranslations } from "~/hooks/useTranslations";
import Link from "next/link";
import { buttonVariants } from "./ui/button";
import { cn } from "~/lib/utils";
import { generatePath } from "~/lib/client/utils";
import { Paths } from "~/lib/Paths";
import { useMediaQuery } from "usehooks-ts";

export function GiftcardInfoBanner(): JSX.Element {
  const {
    shopConfig: { commonConfiguration, giftcardOptions },
  } = useShopConfig();
  const t = useTranslations();
  const isMobile = useMediaQuery("(max-width: 767px)");

  const thumbnail = useMemo(() => {
    return giftcardOptions?.salesImage?.variants.reduce((prev, current) =>
      Number(prev?.width) > Number(current.width) ? prev : current,
    );
  }, [giftcardOptions]);

  return giftcardOptions ? (
    <div
      className={cn(
        !isMobile && "py-50px",
        "container flex w-full items-center justify-center",
      )}
    >
      <div className="flex w-full max-w-[900px] flex-col gap-4 rounded-[16px] bg-[#1C1C1C] p-6 md:flex-row">
        <div className="md:w-1/2">
          <Image
            width={thumbnail?.width}
            height={thumbnail?.height}
            alt="Giftcard"
            src={thumbnail?.url ?? ""}
            className="h-full w-full rounded-[12px] object-cover"
          />
        </div>
        <div className="flex flex-col justify-center p-4 text-white md:w-1/2">
          <h2 className="text-xl font-bold">{t.giftcards.info_banner_title}</h2>
          <p>{t.giftcards.info_banner_description}</p>
          <Link
            className={cn(
              buttonVariants({ variant: "outline" }),
              "mt-4 rounded-[4px] border border-black px-6 py-2 text-sm text-black hover:opacity-85 md:w-auto",
            )}
            style={{ backgroundColor: commonConfiguration?.sloganTextColor }}
            href={generatePath(Paths.GIFTCARDS)}
            prefetch={false}
          >
            <div className="flex justify-center gap-2 align-middle text-lg font-bold">
              <div>{t.giftcards.info_banner_button}</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
