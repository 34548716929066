"use client";

import { useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useShopConfig } from "~/hooks/useShopConfig";
import { useTranslations } from "~/hooks/useTranslations";
import { cn } from "~/lib/utils";
import Image from "next/image";
import Link from "next/link";
import { Paths } from "~/lib/Paths";

export function FanticketBanner(): JSX.Element {
  const t = useTranslations();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const {
    shopConfig: { fanTicket },
  } = useShopConfig();

  const thumbnail = useMemo(() => {
    return fanTicket?.imageVariants.reduce((prev, current) =>
      Number(prev.dimensions?.width) > Number(current.dimensions?.width)
        ? prev
        : current,
    );
  }, [fanTicket]);

  return fanTicket ? (
    <div
      className={cn(
        isDesktop && "py-50px",
        "container flex w-full items-center justify-center",
      )}
    >
      <div className="flex w-full max-w-[900px] flex-col gap-4 rounded-[16px] bg-[#1C1C1C] p-6 md:flex-row">
        <div className="md:w-1/2">
          <Image
            width={thumbnail?.dimensions?.width}
            height={thumbnail?.dimensions?.height}
            alt={thumbnail?.url ?? ""}
            src={thumbnail?.url ?? ""}
            className="h-full w-full rounded-[12px] object-cover"
          />
        </div>
        <div className="flex flex-col justify-center p-4 text-white md:w-1/2">
          <h2
            className={cn(
              isDesktop ? "text-2xl" : "text-md",
              "h-full w-[50%] content-center font-bold text-white",
            )}
          >
            {t.additional_options.fanticket}
          </h2>
          <p>{t.additional_options.description}</p>
          <Link
            href={Paths.FANTICKET}
            className={cn(
              isDesktop ? "max-w-[200px]" : "w-full",
              "mt-4 flex justify-center rounded-[4px] border border-black bg-black p-2",
            )}
          >
            <span
              className={cn(isDesktop ? "text-lg" : "text-sm", "font-bold")}
            >
              {t.additional_options.inform_now}
            </span>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
