"use client";

import { useMediaQuery } from "usehooks-ts";
import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { generatePath } from "~/lib/client/utils";
import { Paths } from "~/lib/Paths";
import Link from "next/link";
import { useTranslations } from "~/hooks/useTranslations";
import { SeriesCarousel } from "./SeriesCarousel";
import { SeriesGrid } from "./SeriesGrid";
import { cn } from "~/lib/utils";
import { useShopConfig } from "~/hooks/useShopConfig";

const maxCards = 4;

interface IEventSlider {
  series: PlainMessage<EventSeries>;
  promotedSeries?: boolean;
}

export default function EventSlider({
  series,
  promotedSeries,
}: Readonly<IEventSlider>): JSX.Element {
  const d = useTranslations();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const {
    shopConfig: { commonConfiguration },
  } = useShopConfig();
  const totalEvents = series.events.length + series.bundles.length;

  return (
    <div
      className={cn(
        promotedSeries && "bg-[#1C1C1C] py-4 text-white",
        "relative pb-4",
      )}
    >
      <Link
        href={generatePath(Paths.SERIES_ANCHOR_OVERVIEW, {
          seriesId: series.id,
        })}
        prefetch={false}
      >
        <h1
          style={{
            color: promotedSeries
              ? commonConfiguration?.sloganTextColor
              : undefined,
          }}
          className="container pt-2 font-anton text-lg font-bold text-black underline underline-offset-2 md:text-xl"
        >
          {promotedSeries ? d.premium_tickets : series.name}
        </h1>
      </Link>
      <Link
        href={generatePath(Paths.SERIES_ANCHOR_OVERVIEW, {
          seriesId: series.id,
        })}
        prefetch={false}
        className="md:blocktext-sm container underline underline-offset-2 md:text-md xl:hidden"
      >
        {totalEvents > 2
          ? d.start_page.view_all_events.replace(
              "{count}",
              totalEvents.toString(),
            )
          : d.start_page.to_overview}
      </Link>

      <div className={cn(!isMobile && "container", "relative")}>
        <div
          className={cn(
            isMobile ? "h-[360px]" : "h-[330px]",
            "flex-no-wrap relative flex w-full items-end justify-center gap-3 overflow-hidden md:justify-start",
          )}
        >
          {isMobile ? (
            <SeriesCarousel series={series} promotedSeries={promotedSeries} />
          ) : (
            <SeriesGrid
              series={series}
              maxCards={maxCards}
              promotedSeries={promotedSeries}
            />
          )}
        </div>
      </div>
    </div>
  );
}
